import moment from 'moment';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    testModeContract: false,
    basket: [],
    customerId: undefined,
    salesSectorType: undefined,
    buttonName: 'Quote',
    job: null,
    reference: '',
    deliveryNotes: '',
    supplierNotes: '',
    poNumber: '',
    deliveryDate: '',
    contractIdentifier: null,
    use_manual_address: false,
    delivery: {},
    isEnquiry: false,
    isFit: true,
    isOrder: false,
    currentItem: undefined,
    contractData: undefined,
    imageType: null,
    skipSop: false,
    isPaymentGateway: false,
  },
  mutations: {
    setIsPaymentGateway(state, isPaymentGateway) {
      state.isPaymentGateway = isPaymentGateway;
    },
    SET_TEST_MODE_CONTRACT(state, setting) {
      state.testModeContract = setting;
    },
    SET_IMAGE_TYPE(state, imageType) {
      state.imageType = imageType;
    },
    setIsFit(state, isFit) {
      state.isFit = isFit;
    },
    setButtonName(state, name) {
      state.buttonName = name;
    },
    setContractData(state, contractData) {
      state.contractData = contractData;
    },
    SET_CUSTOMER_ID(state, value) {
      state.customerId = value;
    },
    SET_SALES_SECTOR_TYPE(state, value) {
      state.salesSectorType = value;
    },
    SET_CURRENT_ITEM(state, value) {
      state.currentItem = value;
    },
    APPEND_BASKET_ITEM(state, item) {
      state.basket.push({
        ...item,
        key: item.key ?? item.itemKey,
        _uuid: new Date().getTime(),
      });
    },
    REPLACE_BASKET_ITEM(state, { itemKey, item }) {
      const itemIndex = state.basket.findIndex(
        (basketItem) => basketItem.itemKey === Number(itemKey),
      );
      Vue.set(state.basket, itemIndex, {
        ...item,
        key: item.key ?? item.itemKey,
        _uuid: new Date().getTime(),
      });
    },
    DELETE_BASKET_ITEM(state, itemKey) {
      state.basket = state.basket.filter((basketItem) => basketItem.itemKey !== Number(itemKey));
    },
    setDeliveryDate(state, value) {
      state.deliveryDate = value;
    },
    setPONumber(state, value) {
      state.poNumber = value;
    },
    setDeliveryNotes(state, value) {
      state.deliveryNotes = value;
    },
    setSupplierNotes(state, value) {
      state.supplierNotes = value;
    },
    saveJobDelivery(state, value) {
      state.delivery = value;
    },
    setReference(state, value) {
      state.reference = value;
    },
    clearBasket(state) {
      state.basket = [];
      state.reference = '';
      state.job = undefined;
      state.contractIdentifier = null;
      state.isEnquiry = false;
      state.skipSop = false;
      state.testModeContract = false;
    },
    setBasket(state, items) {
      // Sort child items in order
      items.sort((a, b) => {
        return a.parentItemKey - b.parentItemKey;
      });
      // Get all parent items that are not job extras
      const parentItems = items.filter(x => x.parentItemKey === 0 && !x.extraItemId)
      for (let i = 0; i < parentItems.length; i++) {
        // Remove from current location
        const parentIndexOld = items.findIndex(item => item.itemKey === parentItems[i].itemKey);
        items.splice(parentIndexOld, 1);     
        // Add above first child element
        const parentIndexNew = items.findIndex(item => item.parentItemKey === parentItems[i].itemKey);
        items.splice(parentIndexNew, 0, parentItems[i]);
      }
      // Sort all job extras to the bottom
      items.sort((a, b) => {
        const jobExtraA = a.parentItemKey === 0 && a.extraItemId
        const jobExtraB = b.parentItemKey === 0 && b.extraItemId
        // If jobExtraA move down
        if (jobExtraA && !jobExtraB) return 1;
        // If jobExtraB move A up
        if (!jobExtraA && jobExtraB) return -1;
      });
      state.basket = items
    },
    setJob(state, job) {
      state.job = job;
    },
    setIsEnquiry(state, value) {
      state.isEnquiry = value;
    },
    setContract(state, contract) {
      state.contractIdentifier = {
        contractId: parseInt(contract.contractId, 10),
        jobKey: parseInt(contract.jobKey, 10),
        existsInBusinessStore: contract.existsInBusinessStore === true,
      };

      state.isEnquiry = !!contract.isEnquiry;
      state.isOrder = !!contract.isOrder;
    },
  },
  actions: {
    async setIsPaymentGateway({ commit }, isPaymentGateway) {
      commit('setIsPaymentGateway', isPaymentGateway);
    },
    clearBasket({ commit }) {
      commit('clearBasket');
    },
    async getSandboxReportUrlForCurrentItem({ state, rootState }) {
      const sandbox = rootState.sandbox.selectedSandbox?.id || 0;
      const customer = state.customerId;
      const { contractId, jobKey } = state.contractIdentifier;
      const { itemKey } = state.currentItem;

      const url = `processing/GenerateFromAdminProcessing/${sandbox}/${customer}/${contractId}/${jobKey}/${itemKey}`;
      return window.touch.displayStream(url, { type: 'application/pdf' });
    },
    async updateStockItemQty(context, { itemId, quantity }) {
    },
    async setFit({ commit, dispatch }, isFit) {
      commit('setIsFit', isFit);

      const contract = await dispatch('getContract');

      await window.touch.processingUpdateFittingType(contract.contractId, contract.jobKey, isFit);

      return dispatch('refresh');
    },
    async updateExistingExtraItem({ dispatch }, { params, itemId }) {
      const contract = await dispatch('getContract');

      await window.touch.processingUpdateExistingExtraItem(
        contract.contractId,
        contract.jobKey,
        itemId,
        params,
      );

      return dispatch('refreshItemByKey', itemId);
    },
    async updateNewExtraItem({ dispatch }, { params, itemId }) {
      const contract = await dispatch('getContract');

      await window.touch.processingUpdateNewExtraItem(
        contract.contractId,
        contract.jobKey,
        itemId,
        params,
      );

      return dispatch('refreshItemByKey', itemId);
    },
    async addExistingExtraItem({ dispatch }, { itemId, extraItemId, params }) {
      const contract = await dispatch('getContract');

      const result = await window.touch.processingAddExistingExtraItem(
        contract.contractId,
        contract.jobKey,
        extraItemId,
        itemId,
        params,
      );

      if (result.status === false) {
        return result;
      }

      return dispatch('appendItemByKey', result.itemKey);
    },
    async getBreakdown({ getters, dispatch }, refresh) {
      if (refresh) {
        await dispatch('refresh', refresh);
      }
      return getters?.currentItem?.priceBreakdown?.sort((a, b) => a.listIndex - b.listIndex);
    },
    async markAsMeasured({ state }) {
      return window.touch.processingUpdateContract(state.contractIdentifier.contractId, {
        NoSizes: false,
      });
    },
    async setMeasurements({ state, dispatch }, measurementsOn) {
      await window.touch.processingUpdateContract(state.contractIdentifier.contractId, {
        NoSizes: !measurementsOn,
      });

      return dispatch('refresh');
    },
    async setCustomerId(context, customerId) {
      if (customerId === undefined) {
        return;
      }

      if (Number.isNaN(Number(customerId))) {
        return;
      }

      context.commit('SET_CUSTOMER_ID', customerId);
    },
    async setSalesSectorType(context, salesSectorType) {
      if (salesSectorType === undefined) {
        return;
      }
      context.commit('SET_SALES_SECTOR_TYPE', salesSectorType);
    },
    async createTest({ commit, state }, isEnquiry = false) {
      commit('clearBasket');
      commit('SET_TEST_MODE_CONTRACT', true);

      const quote = await window.touch.testCreateTestJob(state.customerId);

      if (quote.message) {
        window.alertBox.fire(quote.message);
        return false;
      }

      commit('setContract', {
        contractId: quote.contractId,
        jobKey: quote.jobKey,
        isEnquiry,
      });

      commit('setButtonName', 'Test Job');

      return true;
    },
    async createQuote({ commit, dispatch, rootState, state }, isEnquiry = false) {
      commit('sandbox/SET_SANDBOX', null, { root: true });
      commit('clearBasket');
      if (rootState.auth.demoMode) {
        commit('setReference', window.helpers.fake_reference());
      }

      const quote = await window.touch.createTradeQuote(state.customerId);

      if (quote.message) {
        window.alertBox.fire(quote.message);
        return false;
      }

      commit('setContract', {
        contractId: quote.contractId,
        jobKey: quote.jobKey,
        isEnquiry,
      });

      commit('setButtonName', 'Quote');

      dispatch('refreshDelivery');

      return true;
    },
    async createOrder({ commit, dispatch, rootState, state }) {
      commit('sandbox/SET_SANDBOX', null, { root: true });
      commit('clearBasket');
      if (rootState.auth.demoMode) {
        commit('setReference', window.helpers.fake_reference());
      }

      const quote = await window.touch.createTradeOrder(state.customerId);

      if (quote.message) {
        window.alertBox.fire(quote.message);
        return false;
      }

      commit('setContract', {
        contractId: quote.contractId,
        jobKey: quote.jobKey,
        isOrder: true,
      });

      commit('setButtonName', 'Order');

      dispatch('refreshDelivery');

      return true;
    },
    async fromQuote({ commit, dispatch, state }, contract) {
      return new Promise((resolve, reject) => {
        commit('setContract', {
          contractId: contract.contractId,
          jobKey: contract.jobKey,
          isEnquiry: contract.isEnquiry,
        });

        window.touch
          .processingReloadContract(state.customerId, contract.contractId)
          .then(() => {
            dispatch('refreshDelivery');
            commit('setButtonName', 'Quote');
            resolve();
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    async fromOrder({ commit, dispatch, state }, contract) {
      return new Promise((resolve, reject) => {
        commit('setContract', {
          contractId: contract.contractId,
          jobKey: contract.jobKey,
          isOrder: true,
          existsInBusinessStore: true,
        });

        window.touch
          .processingReloadContract(state.customerId, contract.contractId)
          .then(() => {
            dispatch('refreshDelivery');
            commit('setButtonName', 'Order');
            resolve();
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    async fromEnquiry({ commit, dispatch, state, rootState }, contract) {
      let contractId;

      if (contract.fromDealer) {
        contractId = await window.touch.processingConvertDealerEnquiry(
          contract.contractId,
          [contract.jobKey],
          rootState.auth.processingLevel,
        );
      } else {
        contractId = await window.touch.contractConvertToQuote(contract.contractId, [
          contract.jobKey,
        ]);
      }

      commit('setContract', {
        contractId,
        jobKey: 1,
        isEnquiry: false,
      });

      await window.touch.processingReloadContract(state.customerId, contractId);

      dispatch('refreshDelivery');
    },
    async addDesign({ rootState, dispatch, commit, state }, { designId, productId }) {
      const contract = await dispatch('getContract');

      if (state.contractData === undefined) {
        const contractData = await window.touch.processingContract(contract.contractId);
        commit('setContractData', contractData);
      }

      const addedDesign = await window.touch.createJobLineItem(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        designId,
        productId,
        rootState.auth.processingLevel,
      );

      commit('APPEND_BASKET_ITEM', addedDesign);

      return addedDesign.itemKey;
    },
    async deleteItemByKey({ commit }, itemKey) {
      commit('DELETE_BASKET_ITEM', itemKey);
    },
    async refreshItemByKey({ dispatch, rootState, commit, state }, itemKey) {
      const contract = await dispatch('getContract');

      const basketItems = await window.touch.processingJob(contract.contractId, contract.jobKey);

      if (basketItems === null || basketItems.items === undefined) {
        throw new Error('Unexpected Empty Contract');
      }

      const newItem = basketItems.items.find((item) => item.key === itemKey);

      const fullItem = await window.touch.jobLineItem(
        rootState.basket.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        rootState.auth.processingLevel,
        false,
        false,
        basketItems.exchangeRate,
      );

      commit('REPLACE_BASKET_ITEM', {
        itemKey,
        item: {
          ...newItem,
          ...fullItem,
        },
      });
    },
    async appendItemByKey({ dispatch, rootState, commit, state }, itemKey) {
      const contract = await dispatch('getContract');

      if (state.contractData === undefined) {
        const contractData = await window.touch.processingContract(contract.contractId);
        commit('setContractData', contractData);
      }

      const basketItems = await window.touch.processingJob(contract.contractId, contract.jobKey);

      if (basketItems === null || basketItems.items === undefined) {
        throw new Error('Unexpected Empty Contract');
      }

      const newItem = basketItems.items.find((item) => item.key === itemKey);

      const fullItem = await window.touch.jobLineItem(
        rootState.basket.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        rootState.auth.processingLevel,
        false,
        false,
        basketItems.exchangeRate,
      );

      commit('APPEND_BASKET_ITEM', {
        ...newItem,
        ...fullItem,
      });
    },
    async getChildProducts({ dispatch }, { componentId, width, height, customerId }) {
      const contract = await dispatch('getContract');

      const products = await window.touch.processingGetChildProducts(
        contract.contractId,
        contract.jobKey,
        componentId,
        width,
        height,
        customerId,
      );

      return products;
    },
    async insertDesignIntoBay(
      { dispatch, rootState, state },
      { product, designId, itemId, componentId },
    ) {
      const contract = await dispatch('getContract');

      const response = await window.touch.processingUpdateJobLineItemNestedDesign(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        itemId,
        rootState.auth.processingLevel,
        product,
        designId,
        componentId,
      );

      await dispatch('refreshItemByKey', itemId);

      return response;
    },
    getComponentDimensions(context, componentId) {
      const container = context.getters.currentItem.frameContainers.filter(
        (frameContainer) => frameContainer.id === componentId,
      );

      if (container.length !== 1) {
        throw new Error('Unable to find component');
      }

      return container[0];
    },
    async updateItemDimension(
      { rootState, dispatch, state, commit },
      { itemKey, dimensionId, value },
    ) {
      const contract = await dispatch('getContract');

      const lineItem = await window.touch.updateJobLineItemDimension(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        rootState.auth.processingLevel,
        dimensionId,
        value,
      );

      commit('REPLACE_BASKET_ITEM', {
        itemKey: state.currentItem.itemKey,
        item: lineItem,
      });

      await dispatch('refresh');
      return lineItem;
    },
    async updateItemQty({ rootState, dispatch, commit, state }, { itemKey, qty }) {
      const contract = await dispatch('getContract');

      const lineItem = await window.touch.updateJobLineItemQuantity(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        rootState.auth.processingLevel,
        qty,
      );

      commit('REPLACE_BASKET_ITEM', {
        itemKey,
        item: lineItem,
      });
      await dispatch('refresh');
      return lineItem;
    },
    async updateStockItemQuantity({ rootState, dispatch }, { itemKey, qty }) {
      const contract = await dispatch('getContract');

      return window.touch.UpdateJobLineStockItemQuantity(
        contract.contractId,
        contract.jobKey,
        itemKey,
        rootState.auth.processingLevel,
        qty,
      );
    },
    async updateImageTypeForHeading({ dispatch, commit }, headingId) {
      const imageType = await dispatch('imageTypeForHeading', { heading: headingId });

      if (imageType) {
        commit('SET_IMAGE_TYPE', imageType);
      }
    },
    async imageTypeForHeading({ getters }, { heading }) {
      try {
        return getters.currentItem.specification.filter(
          (spec) => spec.optionHeadingId === heading,
        )[0].drawingType;
      } catch (error) {
        return false;
      }
    },
    async updateItemOption(
      { rootState, dispatch, state, commit },
      { itemKey, heading, value, components, members, text, rule, parameters },
    ) {
      const contract = await dispatch('getContract');

      const lineItem = await window.touch.UpdateJobLineItemOption(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        rootState.auth.processingLevel,
        heading,
        value,
        components,
        members,
        parameters,
        text,
        rule,
      );

      commit('REPLACE_BASKET_ITEM', {
        itemKey: state.currentItem.itemKey,
        item: lineItem,
      });

      return lineItem;
    },
    async loadItem({ rootState, state, dispatch }, { itemKey, customerID }) {
      if (state.basket.length === 0) {
        await dispatch('refresh');
      }
      if (state.basket.length > 0) {
        const item = state.basket.filter((x) => Number(x.key) === Number(itemKey))[0];

        if (!item) {
          return undefined
        }
        const fullItem = await window.touch.jobLineItem(
          customerID,
          item.contractId,
          item.jobKey,
          item.itemKey,
          rootState.auth.processingLevel,
          false,
          false,
          state.job.exchangeRate,
        );
        return fullItem;
      }
      return undefined;
    },
    async getEarliestDeliveryDate({ dispatch }) {
      const contract = await dispatch('getContract');
      return window.touch.processingGetEarliestDeliveryDate(contract.contractId, contract.jobKey);
    },
    async processingForceDefaults({ state, dispatch, rootState }) {
      const contract = await dispatch('getContract');

      await window.touch.processingForceDefaults(
        contract.contractId,
        contract.jobKey,
        state.currentItem,
        rootState.auth.processingLevel,
      );
      return dispatch('refreshItemByKey', state.currentItem);
    },
    async refresh({ commit, dispatch, rootState }) {
      const contract = await dispatch('getContract');

      window.touch.processingContract(contract.contractId).then((contractData) => {
        commit('setContractData', contractData);
      });

      const uuid = new Date().getTime();
      const basketSummary = await window.touch.processingBasketSummary(
        rootState.basket.customerId,
        contract.contractId,
        contract.jobKey,
        rootState.auth.processingLevel,
      );
      if (basketSummary) {
        for (let i = 0; i < basketSummary.items.length; i++) {
          // eslint-disable-line
          basketSummary.items[i].key = basketSummary.items[i].itemKey;
          basketSummary.items[i]._uuid = uuid + 1; // eslint-disable-line
        }
        commit('setJob', {
          exchangeRate: basketSummary.exchangeRate,
          fittingType: basketSummary.fittingType,
          tax: basketSummary.tax,
          taxRate: basketSummary.taxRate,
          calculatedPriceTaxable: basketSummary.calculatedPriceTaxable,
          calculatedPriceTaxExempt: basketSummary.calculatedPriceTaxExempt,
          calculatedFittingPriceTaxable: basketSummary.calculatedFittingPriceTaxable,
          calculatedFittingPriceTaxExempt: basketSummary.calculatedFittingPriceTaxExempt,
          priceDeliveryCharge: basketSummary.priceDeliveryCharge,
          priceDeliveryChargeIncTax: basketSummary.priceDeliveryChargeIncTax,
          priceIncludingTax: basketSummary.priceIncludingTax,
          fittingPrice: basketSummary.fittingPrice,
          fittingPriceIncTax: basketSummary.fittingPriceIncTax,
          surveyPrice: basketSummary.surveyPrice,
          surveyPriceIncTax: basketSummary.surveyPriceIncTax,
          subtotal: basketSummary.priceExcludingTax,
          total: basketSummary.priceIncludingTax,
          overrideCalculatedPrice: basketSummary.overrideCalculatedPrice,
          overridePriceTaxExempt: basketSummary.overridePriceTaxExempt,
          overridePriceTaxable: basketSummary.overridePriceTaxable,
          discountApplied: basketSummary.discountApplied,
          discountCode: basketSummary.discountCode,
        });
        commit('setIsFit', basketSummary.fittingType === window.enum.fittingType.SUPPLY_AND_FIT);
        commit('setBasket', basketSummary.items);
      }
    },
    setReference({ commit }, reference) {
      commit('setReference', reference);
    },
    async saveBrandedEnquiry(
      { commit, dispatch, state },
      {
        salutation,
        firstName,
        lastName,
        email,
        phone,
        addressLine1,
        addressLine2,
        addressLine3,
        addressTown,
        addressCode,
        addressIso,
        notes,
        latitude,
        longitude,
      },
    ) {
      const contract = await dispatch('getContract');
      // set quote reference
      const quoteId = contract.contractId;

      commit('clearBasket');

      await window.touch.processingUpdateContract(contract.contractId, {
        Reference: state.reference,
        Consumer: {
          Salutation: salutation,
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Mobile: phone,
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
          AddressTown: addressTown,
          AddressCode: addressCode,
          AddressIso: addressIso,
          AddressLatitude: latitude,
          AddressLongitude: longitude,
        },
        OverwriteConsumer: true,
      });

      await window.touch.processingUpdateNote({
        ContractId: contract.contractId,
        JobKey: 1,
        NoteType: window.enum.noteType.CUSTOMER,
        Text: notes,
      });

      await window.touch.processingSaveContract(state.customerId, contract.contractId);
      dispatch('visualiser/clear', {}, { root: true });

      return quoteId;
    },
    async updateOrder({ dispatch, state, commit }) {
      const contract = await dispatch('getContract');
      // set quote reference

      await window.touch.processingUpdateContract(contract.contractId, {
        Reference: state.reference,
      });

      if (contract.jobKey === 1) {
        await window.touch.processingUpdateJob(contract.contractId, contract.jobKey, {
          Reference: state.reference,
        });
      }

      if (!state.skipSop) {
        await window.touch.processingSaveContract(
          state.customerId,
          contract.contractId,
          state.skipSop,
          false,
        );
      }

      const orderId = contract.contractId;

      if (!state.skipSop) {
        commit('clearBasket');
      }

      return {
        orderId,
      };
    },
    async saveQuote({ commit, dispatch, state }, isLazySaving = true) {
      const contract = await dispatch('getContract');
      // set quote reference
      let quoteId;

      if (state.isEnquiry) {
        quoteId = await window.touch.contractConvertToQuote(contract.contractId, [contract.jobKey]);
        // quoteId = await window.touch.processingConvertDealerEnquiry(contract.contractId, [contract.jobKey]);
      } else {
        quoteId = contract.contractId;
      }

      if (state.isEnquiry) {
        // await window.touch.processingSaveContract(state.customerId, contract.contractId);
      }

      const jobId = state.isEnquiry ? 1 : contract.jobKey;

      await window.touch.processingUpdateContract(quoteId, {
        Reference: state.reference,
      });

      if (jobId === 1) {
        await window.touch.processingUpdateJob(quoteId, jobId, {
          Reference: state.reference,
        });
      }

      await window.touch.processingSaveContract(
        state.customerId,
        quoteId,
        false,
        false,
        isLazySaving,
      );
      commit('clearBasket');

      return {
        jobId,
        quoteId,
      };
    },
    async saveOrder({ commit, dispatch, state }, { isLazySaving = true, placeTradeOrder = false }) {
      const contract = await dispatch('getContract');
      // set quote reference
      // const quoteId = contract.contractId;
      const orderId = contract.contractId;
      if (!state.isOrder) {
        // await window.touch.processingSaveContract(state.customerId, quoteId);
        // orderId = await window.touch.contractConvertToOrder(quoteId, [contract.jobKey]);
      }

      await window.touch.processingUpdateContract(orderId, {
        Reference: state.reference,
      });

      await window.touch.processingUpdateJobDelivery(state.customerId, orderId, 1, {
        RequestedDate: moment(state.deliveryDate).add(12, 'hours').toISOString(),
        Notes: state.deliveryNotes,
      });

      let existingNotes;
      if (contract.existsInBusinessStore) {
        existingNotes = await dispatch(
          'touch/loadNote',
          {
            contractId: orderId,
            jobId: 1,
            noteType: window.enum.noteType.SUPPLIER,
          },
          { root: true },
        );
      }
      const existingSupplierNoteId = existingNotes?.id;

      await window.touch.processingUpdateNote({
        NoteId: existingSupplierNoteId,
        ContractId: orderId,
        JobKey: 1,
        NoteType: window.enum.noteType.SUPPLIER,
        Text: state.supplierNotes,
      });

      await window.touch.processingUpdateCustomerPONumber(orderId, state.poNumber);

      // caching this here as we clear it now as well;
      const skippingSop = state.skipSop;

      commit('clearBasket');
      // await window.touch.processingReloadContract(state.customerId, quoteId);

      if (!state.isOrder) {
        await window.touch.processingSaveContract(
          state.customerId,
          orderId,
          false,
          false,
          isLazySaving,
        );
        return orderId;
      }

      if (state.salesSectorType === window.enum.salesSectorType.RETAIL) {
        await window.touch.processingSaveContract(
          state.customerId,
          orderId,
          false,
          placeTradeOrder,
          isLazySaving,
        );
        return orderId;
      }

      return window.touch.processingSaveContract(
        state.customerId,
        orderId,
        skippingSop,
        !skippingSop,
        isLazySaving,
      );
    },
    async setAddress({ state, dispatch, commit }, addressId) {
      const contract = await dispatch('getContract');

      await window.touch.processingUpdateJobDelivery(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        {
          AddressId: parseInt(addressId, 10),
        },
      );
      
      commit('setDeliveryDate', '')

      if (addressId) {
        dispatch('refreshDelivery');
      }
    },
    async addUserEnteredAddress(
      { state, dispatch },
      { town, address1, address2, address3, iso, postcode, latitude, longitude },
    ) {
      const contract = await dispatch('getContract');

      await window.touch.processingUpdateJobDelivery(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        {
          NewAddress: {
            Line1: address1,
            Line2: address2,
            Line3: address3,
            Town: town,
            Iso: iso,
            Code: postcode,
            Latitude: latitude,
            Longitude: longitude,
          },
        },
      );

      dispatch('refreshDelivery');
    },
    async getContract({ state, commit }) {
      if (!state.contractIdentifier) {
        throw new Error('No Contract Setup');
      }

      if (!state.job) {
        commit(
          'setJob',
          await window.touch.processingJob(
            state.contractIdentifier.contractId,
            state.contractIdentifier.jobKey,
            false,
          ),
        );
      }

      return {
        contractId: state.contractIdentifier.contractId,
        jobKey: state.contractIdentifier.jobKey,
        existsInBusinessStore: state.contractIdentifier.existsInBusinessStore,
      };
    },
    async refreshDelivery({ state, commit, dispatch }, existingContract) {
      if (state.isEnquiry) {
        return; // We don't need delivery for enquiries
      }
      const contract = existingContract ?? (await dispatch('getContract'));

      try {
        commit(
          'saveJobDelivery',
          await window.touch.processingJobDelivery(
            state.customerId,
            contract.contractId,
            contract.jobKey,
          ),
        );
        dispatch('pickDefaultIfNoDelivery');
      } catch (error) {
        // not ready
      }
    },
    async pickDefaultIfNoDelivery({ state, dispatch }) {
      if (state.delivery.addressId === 0) {
        const primaryAddress = state.delivery.addresses.find((address) => address.isPrimary);
        if (primaryAddress) {
          dispatch('setAddress', primaryAddress.id);
        }
      }
    },
    async deleteLineItem({ dispatch }, { lineItemId }) {
      const contract = await dispatch('getContract');
      await window.touch.processingDeleteLineItem(
        contract.contractId,
        contract.jobKey,
        lineItemId,
        10,
      );
      await dispatch('visualiser/deleteProduct', { id: lineItemId }, { root: true });
      dispatch('deleteItemByKey', lineItemId);
      await dispatch('refresh');
    },
    async duplicateLineItem({ dispatch, state }, { lineItemId }) {
      const contract = await dispatch('getContract');
      const newItem = await window.touch.processingDuplicateItem(
        state.customerId,
        contract.contractId,
        contract.jobKey,
        lineItemId,
        10,
      );
      await dispatch('refresh');

      return newItem;
    },
    async resetJobLineItem({ rootState, dispatch }, { itemKey }) {
      const contract = await dispatch('getContract');
      return window.touch.resetJobLineItem(
        contract.contractId,
        contract.jobKey,
        itemKey,
        rootState.auth.processingLevel,
      );
    },
    async getProcessingImage({ dispatch }, { itemKey, imageType }) {
      const contract = await dispatch('getContract');
      const image = await window.touch.processingImage(
        contract.contractId,
        contract.jobKey,
        itemKey,
        imageType,
      );
      return image;
    },
  },
  getters: {
    basketQty(state, getters) {
      return (
        getters.stockItems.length + getters.jobLevelExtras.length + getters.fenestrationItems.length
      );
    },
    stockItems(state) {
      return state.basket
        .filter((item) => item.inputType === window.enum.inputType.STOCK_ITEM)
        .filter((item) => item);
    },
    jobLevelExtras(state) {
      return state.basket
        .filter((item) => item.inputType === window.enum.inputType.CUSTOMER)
        .filter((item) => item)
        .filter((item) => item.parentItemKey === 0);
    },
    fenestrationItems(state) {
      return state.basket
        .filter((item) => item.inputType === window.enum.inputType.FENESTRATION)
        .filter((item) => item);
    },
    contractHasSizing(state) {
      return state.contractData && !state.contractData.noSizes;
    },
    hasContract(state) {
      return state.contractIdentifier !== null;
    },
    customerId(state) {
      return state.customerId;
    },
    getCurrency(state) {
      if (state.contractData === undefined) {
        return '';
      }

      return state.contractData.customerCurrencyCode;
    },
    currentItem(state) {
      return state.currentItem;
    },
    availableDeliveryDates(state) {
      try {
        return state.delivery.deliveryDates.map((date) => moment(date).format('DD/MM/YYYY'));
      } catch (error) {
        return [error];
      }
    },
    selected_address_id(state) {
      try {
        return state.delivery.addressId;
      } catch (error) {
        return -1;
      }
    },
    available_addresses(state) {
      try {
        return state.delivery.addresses; // .filter(address => address.isSelectable);
      } catch (error) {
        return [];
      }
    },
    total(state) {
      try {
        return state.job.priceIncludingTax;
      } catch (error) {
        return 0;
      }
    },
    fittingPrice(state) {
      try {
        if (state.salesSectorType === window.enum.salesSectorType.RETAIL) {
          return state.job.fittingPriceIncTax;
        }
        return state.job.fittingPrice;
      } catch (error) {
        return 0;
      }
    },
    surveyPrice(state) {
      try {
        if (state.salesSectorType === window.enum.salesSectorType.RETAIL) {
          return state.job.surveyPriceIncTax;
        }
        return state.job.surveyPrice;
      } catch (error) {
        return 0;
      }
    },
    vat(state) {
      try {
        return state.job.tax;
      } catch (error) {
        return 0;
      }
    },
    delivery(state) {
      try {
        if (state.salesSectorType === window.enum.salesSectorType.RETAIL) {
          return state.job.priceDeliveryChargeIncTax;
        }
        return state.job.priceDeliveryCharge;
      } catch (error) {
        return 0;
      }
    },
    subtotal(state) {
      try {
        if (window.VUE_APP_OVERRIDE === 'vendor') {
          return state.job.priceBeforeTax;
        }

        return state.job.subtotal;
      } catch (error) {
        return 0;
      }
    },
  },
};
